import React from 'react';
import './Footer.css';

const Footer = () => {
  return(
    <div className='footer'>
      <span>Copyright © Parafia pw. bl. Honorata 2021</span>
    </div>
  )
}

export default Footer;